body {
  margin: 0;
  font-family: 'Georgia serif';
  background-color: #F3EAD6;
}

div{
  background-color: #fff9f9;
  /* border: 15px solid #FD9584; */
  
}
h1, h2, h3{
  color: #FD9584;
  /* color: #F6B6A7 */
}

p, li{
  color:#A66D45
}

a{
  
  color: #A7A155;
  background-color: #D2C98A;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
